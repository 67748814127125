import React from 'react'
import Slider from 'react-slick';
import { Link } from 'react-router-dom'
import BG1 from '../../assets/images/capa-parmeca-01.jpg'
import BG2 from '../../assets/images/parmegiana-capa.webp'
import BG3 from '../../assets/images/carnes-parmeca-foto.webp'

const Bannerslider = {
    responsive: [
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
            },
        },
        {
            breakpoint: 1000,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
}

function Banner() {
    return (
        <>
            <section className="banner_section second_banner">
                <div className="banner_slider" data-aos="fade-up" data-aos-duration={3000} data-aos-offset={0} >
                    <Slider className="" id="banner_slider" {...Bannerslider} arrows={false} slidesToShow={1} autoplay={true} autoplaySpeed={5000}>
                        <div className="item">
                            <div className="slider_block" style={{ backgroundImage: `url(${BG1})` }} >
                                <div className="ban_text ">
                                    <div className="inner_text">
                                        <span className="sub_text">Seja Bem vindo ao</span>
                                        <h1>Parmeca.</h1>
                                        <p className="lead">
                                            A melhor parmegiana de São Paulo você encontra aqui.{" "}
                                        </p>
                                        <Link to="https://deliverydireto.com.br/parmeca" className="btn btn_primary">
                                            PEÇA PELO APLICATIVO
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="slider_block" style={{ backgroundImage: `url(${BG2})` }} >
                                <div className="ban_text ">
                                    <div className="inner_text">
                                        <span className="sub_text">Delivery de Parmegiana</span>
                                        <h1>Em São Paulo</h1>
                                        <p className="lead">
                                            Receba em minutos a melhor parmegiana de São Paulo.{" "}
                                        </p>
                                        <Link to="https://deliverydireto.com.br/parmeca" className="btn btn_primary">
                                            PEÇA PELO APLICATIVO
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="slider_block" style={{ backgroundImage: `url(${BG3})` }} >
                                <div className="ban_text">
                                    <div className="inner_text">
                                        <span className="sub_text">O melhor da Culinária Italiana</span>
                                        <h1>Você encontra aqui no Parmeca</h1>
                                        <p className="lead">
                                        Aproveite nossas promoções no app{" "}
                                        </p>
                                        <Link to="https://deliverydireto.com.br/parmeca" className="btn btn_primary">
                                            PEÇA PELO APLICATIVO
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
        </>
    )
}

export default Banner