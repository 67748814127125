import React from 'react'
import Dish01 from '../../assets/images/parmegiana-strogonoff.png'
import Icon01 from '../../assets/images/outj_icon1.png'
import Icon02 from '../../assets/images/outj_icon2.png'
import Icon03 from '../../assets/images/outj_icon3.png'

function Journey() {
  return (
    <>
      <section className="row_am our_journey bg_white_textcher">
        <div className="side_shape position-absolute" data-aos="fade-in" data-aos-duration={1500} >
        </div>
        <div className="container">
          <div className="row_am">
            <div className="section_title" data-aos="fade-up" data-aos-duration={1500} >
              <span className="sub_text">Por que o Parmeca?</span>
              <h2>
                Desde 2020 oferecendo <br /> o melhor da Culinária Italiana
              </h2>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="joueney_box" data-aos="fade-up" data-aos-duration={1500} >
                  <div className="icon">
                    <img src={Icon01} alt="img" />
                  </div>
                  <h3>Pertinho de Você</h3>
                  <p>
                    Estamos localizados em São Paulo e contamos com dois endereços físicos,
                    um na Liberdade e outro em Perdizes! Escolha o que fica mais perto de você 
                    e peça a melhor parmegiana da Cidade!
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="joueney_box" data-aos="fade-up" data-aos-duration={1500} >
                  <div className="icon">
                    <img src={Icon02} alt="img" />
                  </div>
                  <h3>Entrega Rápida</h3>
                  <p>
                    Atendemos 100% via delivery.
                    Compromisso é que a comida chegue quentinha e dentro do tempo 
                    no seu horário de almoço ou jantar.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="joueney_box" data-aos="fade-up" data-aos-duration={1500} >
                  <div className="icon">
                    <img src={Icon03} alt="img" />
                  </div>
                  <h3>Pratos Diferenciados</h3>
                  <p>
                    Se você procura por uma ampla variedade de Massas e Parmegianas, feitas com os melhores ingredientes
                    o Parmeca foi feito para você!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Journey