import React from 'react'
import { Link } from 'react-router-dom'
import Img1 from '../../assets/images/liguini-parmeca.png'
import Img2 from '../../assets/images/massa-pene-100x100x.png'
import Img3 from '../../assets/images/gnocchi-de-batata.png'
import Img4 from '../../assets/images/gnocchi-de-abobora.png'
import Img5 from '../../assets/images/massas-parmeca.png'
import Img6 from '../../assets/images/ravioli-de-tomate-seco-100x100.png'
import Img7 from '../../assets/images/ravioli-verde-100x100.png'
import Img8 from '../../assets/images/capoletti-de-carne.png'


function Dishlist1() {
    return (
        <>
            <section className="row_am dishList_block">
                <div className="container">
                    <div className="row flex-column-reverse flex-lg-row">
                        <div className="col-md-12 col-lg-6" data-aos="fade-up" data-aos-duration={1500} >
                            <div className="dish_list">
                                <ul>
                                    <li>
                                        <div className="img">
                                            <img src={Img1} alt="img" />
                                        </div>
                                        <div className="info">
                                            <div className="mainCource">
                                                <div className="dishName">
                                                    <h4>Linguini</h4>
                                                </div>
                                                <div className="doted" />
                                            </div>
                                            <div className="description">
                                                <p>
                                                    Massa fresca artesanal feita na manteiga com ervas. O Linguini tem inspiração na forma...
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img src={Img2} alt="img" />
                                        </div>
                                        <div className="info">
                                            <div className="mainCource">
                                                <div className="dishName">
                                                    <h4>Penne</h4>
                                                </div>
                                                <div className="doted" />
                                            </div>
                                            <div className="description">
                                                <p>
                                                    Massa de grano duro feita na manteiga com ervas. Penne é um dos formatos de massa italiana mais famosos...
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img src={Img3} alt="img" />
                                        </div>
                                        <div className="info">
                                            <div className="mainCource">
                                                <div className="dishName">
                                                    <h4>Gnocchi de Batata</h4>
                                                </div>
                                                <div className="doted" />
                                            </div>
                                            <div className="description">
                                                <p>
                                                    Massa artesanal feita à base de batata, tradição que vem desde o tempo do Império Romano.
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img src={Img6} alt="img" />
                                        </div>
                                        <div className="info">
                                            <div className="mainCource">
                                                <div className="dishName">
                                                    <h4>Ravioli de Búfala e Tomate Seco</h4>
                                                </div>
                                                <div className="doted" />
                                            </div>
                                            <div className="description">
                                                <p>
                                                    Massa fresca artesanal recheada com mussarela de búfala e tomates secos...
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img src={Img7} alt="img" />
                                        </div>
                                        <div className="info">
                                            <div className="mainCource">
                                                <div className="dishName">
                                                    <h4>Ravioli Verde de Búfala e Pesto</h4>
                                                </div>
                                                <div className="doted" />
                                            </div>
                                            <div className="description">
                                                <p>
                                                    Massa fresca artesanal recheada com mussarela de búfala e pesto de manjericão...
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <img src={Img8} alt="img" />
                                        </div>
                                        <div className="info">
                                            <div className="mainCource">
                                                <div className="dishName">
                                                    <h4>Cappelletti de Carne</h4>
                                                </div>
                                                <div className="doted" />
                                            </div>
                                            <div className="description">
                                                <p>
                                                    Massa fresca artesanal recheada com carne moída temperada...
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <Link to="https://deliverydireto.com.br/parmeca" className="btn btn_primary">
                                    Peça pelo App
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-6" data-aos="fade-up" data-aos-duration={1500} >
                            <div className="dish_img dish_right">
                                <img className="img-fluid" src={Img5} alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Dishlist1