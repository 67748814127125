import React from 'react'
import { Route, Routes,  } from 'react-router-dom';
import Home from '../components/Home/Main'
import MenuList1 from '../components/MenuList1/Main'
import About from '../components/About/Main'
import Reviews from '../components/Reviews/Main'
import OurChef from '../components/OurChef/Main'
import Contact from '../components/Contact/Main'
import Gallery from '../components/Gallery/Main'

function Index() {
    
  return (
    <>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/menu" element={<MenuList1 />} />
          <Route path="/sobrenos" element={<About />} />
          <Route path="/franquia-parmegiana" element={<Reviews />} />
          <Route path="/ourchef" element={<OurChef />} />
          <Route path="/contato" element={<Contact/>}/>
          <Route path="/gallery" element={<Gallery />} />
        </Routes>
    </>
  )
}

export default Index