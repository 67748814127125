import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Dishimg from '../../assets/images/Filé-Mignon-Gnocchi.png'
import ImgDish01 from '../../assets/images/file-mingon-a-parmegiana.png'
import ImgDish02 from '../../assets/images/file-mingon-suino-a-parmegiana.png'
import ImgDish03 from '../../assets/images/file-de-frango-a-parmegiana.png'
import ImgDish04 from '../../assets/images/beringela-a-parmegiana.png'
import ImgDish05 from '../../assets/images/parmegiana-mais-massa.png'
import ImgDish06 from '../../assets/images/parmegiana4queijos.png'
import ImgDish07 from '../../assets/images/croccante-mais-2-acompanhamentos.png'
import ImgDish08 from '../../assets/images/grelhado-mais-dois-acompanhamentos.png'
import ImgDish09 from '../../assets/images/monte-suas-massas-parmeca.png'
import ImgDish10 from '../../assets/images/parmeca-e-pasta-bambino.png'
import ImgDish11 from '../../assets/images/light-bambino-parmeca.png'
import ImgDish12 from '../../assets/images/vegetariano-bambino-parmeca.png'
import ImgDish13 from '../../assets/images/file-mingnon-com-fritas.webp'
import ImgDish14 from '../../assets/images/strogonoff-com-arroz-parmeca.png'
import ImgDish15 from '../../assets/images/brownie-de-leite-parmeca.png'
import ImgDish16 from '../../assets/images/brownie-com-nozes.png'
import ImgDish17 from '../../assets/images/brownie-duo-parmeca.png'
import ImgDish18 from '../../assets/images/minicups-haagen-dazs.png'
import ImgDish19 from '../../assets/images/brownie-meio-amargo.png'
import ImgDish21 from '../../assets/images/milanesa-crocante-foto.webp'
import ImgDish22 from '../../assets/images/parmegiana-grelhado-e-assado.webp'
import ImgDish23 from '../../assets/images/ragu-de-file-mingnon.webp'
import ImgDish24 from '../../assets/images/brownie-nutella.png'
function MenuSection() {

    const [tabMenu, tabActive] = useState({ a: true });


    return (
        <>
            <section className="our_menu_section row_inner_am light_texchur">
                <div className="side_shape">
                    <img className="right_side" src={Dishimg} alt="img" />
                </div>
                <div className="container">
                    <div className="section_title text-center" data-aos="fade-up" data-aos-duration={1500} >
                        <span className="sub_text">Nosso Menu</span>
                        <h2>Os melhores pratos</h2>
                    </div>
                    <ul className="nav nav-tabs" id="myTab" role="tablist" data-aos="fade-up" data-aos-duration={1500} >
                        <li className="nav-item">
                            <Link className={`nav-link ${tabMenu.a && "active"}`}
                                onClick={() => tabActive({ a: true })} id="starters-tab" data-toggle="tab" to="#starters" role="tab" aria-controls="starters" aria-selected="true" >
                                PARMEGIANAS
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${tabMenu.b && "active"}`}
                                onClick={() => tabActive({ b: true })} id="steaks-tab" data-toggle="tab" to="#steaks" role="tab" aria-controls="steaks" aria-selected="false" >
                                DO CHEFE
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${tabMenu.c && "active"}`}
                                onClick={() => tabActive({ c: true })} id="special-tab" data-toggle="tab" to="#special" role="tab" aria-controls="special" aria-selected="false" >
                                EXECUTIVOS
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${tabMenu.d && "active"}`}
                                onClick={() => tabActive({ d: true })} id="deserts-tab" data-toggle="tab" to="#deserts" role="tab" aria-controls="deserts" aria-selected="false" >
                                SOBREMESAS
                            </Link>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent" data-aos="fade-up" data-aos-duration={1500} >

                        <div className={`tab-pane fade ${tabMenu.a && "show active"}`} id="starters" role="tabpanel" aria-labelledby="starters-tab" >
                            <div className="container">
                                <div className="row">
                                <div className="col-lg-6 col-md-12">
                                        <div className="dish_box">
                                            <div className="dish_info">
                                                <div className="dish_img">
                                                    <img src={ImgDish01} alt="img" />
                                                </div>
                                                <div className="dish_text">
                                                    <h3>Parmegiana de Filé Mignon</h3>
                                                    <p>
                                                        Filé mignon empanado na farinha panko (aprox. 150g) + 
                                                        diversas opções de molho e queijo + 2 de nossos acompanhamentos.{" "}
                                                    </p>
                                                    <span className="price">A partir de R$74,90</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="dish_box">
                                            <div className="dish_info">
                                                <div className="dish_img">
                                                    <img src={ImgDish02} alt="img" />
                                                </div>
                                                <div className="dish_text">
                                                    <h3>Filé Mignon Suíno</h3>
                                                    <p>
                                                        Filé Mignon Suíno empanado na farinha panko (aprox. 150g) 
                                                        + diversas opções de molho e queijo + 2 de nossos acompanhamentos.{" "}
                                                    </p>
                                                    <span className="price">A partir de R$69,90</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="dish_box">
                                            <div className="dish_info">
                                                <div className="dish_img">
                                                    <img src={ImgDish03} alt="img" />
                                                </div>
                                                <div className="dish_text">
                                                    <h3>Peito de Frango</h3>
                                                    <p>
                                                    Peito de frango empanado na farinha panko (aprox. 150g) 
                                                    + diversas opções de molho e queijo + 2 de nossos acompanhamentos.
                                                    </p>
                                                    <span className="price">A partir de R$59,90</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="dish_box">
                                            <div className="dish_info">
                                                <div className="dish_img">
                                                    <img src={ImgDish05} alt="img" />
                                                </div>
                                                <div className="dish_text">
                                                    <h3>Parmegiana + Massa</h3>
                                                    <p>
                                                    Parmegiana + Massa do seu jeito: Escolha entre filé mignon, 
                                                    peito de frango ou vegetariana + molho e queijo...
                                                    </p>
                                                    <span className="price">A partir de R$59,90</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <Link to="/menu" className="btn btn_primary">
                                        MENU COMPLETO
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className={`tab-pane fade ${tabMenu.b && "show active"}`} id="steaks" role="tabpanel" aria-labelledby="steaks-tab" >
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                        <div className="dish_box">
                                            <div className="dish_info">
                                                <div className="dish_img">
                                                    <img src={ImgDish07} alt="img" />
                                                </div>
                                                <div className="dish_text">
                                                    <h3>Croccante + 2 Acompanhamentos</h3>
                                                    <p>
                                                    Monte seu à Milanesa: Filé mignon bovino e suíno, Peito de frango 
                                                    ou Vegetariano (abobrinha e berinjela)...
                                                    </p>
                                                    <span className="price">A partir de R$49,90</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="dish_box">
                                            <div className="dish_info">
                                                <div className="dish_img">
                                                    <img src={ImgDish06} alt="img" />
                                                </div>
                                                <div className="dish_text">
                                                    <h3>Parmegiana 4 Queijos</h3>
                                                    <p>
                                                    Monte seu Parmeca 4 Queijos: Filé mignon bovino e suíno, Peito de 
                                                    frango ou Vegetariano empanado...{" "}
                                                    </p>
                                                    <span className="price">A partir de R$69,90</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="dish_box">
                                            <div className="dish_info">
                                                <div className="dish_img">
                                                    <img src={ImgDish08} alt="img" />
                                                </div>
                                                <div className="dish_text">
                                                    <h3>Grelhado + 2 Acompanhamentos</h3>
                                                    <p>
                                                    Monte seu grelhado: Filé mignon bovino e suíno ou Peito de frango 
                                                    grelhado (aprox. 200g) + 2 de nossos acompanhamentos.{" "}
                                                    </p>
                                                    <span className="price">A partir de R$49,90</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="dish_box">
                                            <div className="dish_info">
                                                <div className="dish_img">
                                                    <img src={ImgDish09} alt="img" />
                                                </div>
                                                <div className="dish_text">
                                                    <h3>Monte sua Massa</h3>
                                                    <p>
                                                    Novas Massas - Monte sua massa fresca artesanal: Linguini, penne, 
                                                    gnocchi, ravioli ou cappelletti, salteado na manteiga com ervas{" "}
                                                    </p>
                                                    <span className="price">A partir de R$44,90</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <Link to="/menu" className="btn btn_primary">
                                        MENU COMPLETO
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className={`tab-pane fade ${tabMenu.c && "show active"}`} id="special" role="tabpanel" aria-labelledby="special-tab" >
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                        <div className="dish_box">
                                            <div className="dish_info">
                                                <div className="dish_img">
                                                    <img src={ImgDish10} alt="img" />
                                                </div>
                                                <div className="dish_text">
                                                    <h3>Parmeca e Pasta - Bambino</h3>
                                                    <p>
                                                    Tiras de peito de frango ou filé mignon empanados na farinha panko (aprox. 100g) 
                                                    + diversas opções de molho e queijo...{" "}
                                                    </p>
                                                    <span className="price">A partir de R$39,90</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="dish_box">
                                            <div className="dish_info">
                                                <div className="dish_img">
                                                    <img src={ImgDish11} alt="img" />
                                                </div>
                                                <div className="dish_text">
                                                    <h3>Light - Bambino</h3>
                                                    <p>
                                                    Tiras de peito de frango ou filé mignon laqueado (aprox. 100g) + legumes grelhados
                                                    na manteiga com ervas...
                                                    </p>
                                                    <span className="price">A partir de R$44,90</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="dish_box">
                                            <div className="dish_info">
                                                <div className="dish_img">
                                                    <img src={ImgDish12} alt="img" />
                                                </div>
                                                <div className="dish_text">
                                                    <h3>Vegetariano - Bambino</h3>
                                                    <p>
                                                        Abobrinha e berinjela empanados na farinha panko (aprox. 100g) 
                                                        + batata palito frita em óleo de flor de algodão...
                                                    </p>
                                                    <span className="price">A partir de R$39,90</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="dish_box">
                                            <div className="dish_info">
                                                <div className="dish_img">
                                                    <img src={ImgDish14} alt="img" />
                                                </div>
                                                <div className="dish_text">
                                                    <h3>Strogonoff de Filé Mignon</h3>
                                                    <p>
                                                    Tiras de filé mignon grelhado e flambado com cogumelos frescos, 
                                                    abraçado com delicioso molho à base de sugo de tomates frescos + arroz...
                                                    </p>
                                                    <span className="price">A partir de R$59,90</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="dish_box">
                                            <div className="dish_info">
                                                <div className="dish_img">
                                                    <img src={ImgDish21} alt="img" />
                                                </div>
                                                <div className="dish_text">
                                                    <h3>Milanesa Croccante – Bambino</h3>
                                                    <p>
                                                    Filé mignon, peito de frango ou vegetariano empanados na farinha panko, cortados em tiras e finalizados com raspas de limão + purê de batatas feito com manteiga de ervas frescas + arroz branco temperado.
                                                    </p>
                                                    <span className="price">A partir de R$48,90</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="dish_box">
                                            <div className="dish_info">
                                                <div className="dish_img">
                                                    <img src={ImgDish13} alt="img" />
                                                </div>
                                                <div className="dish_text">
                                                    <h3>Filé com fritas - Bambino</h3>
                                                    <p>
                                                    Filé mignon grelhado na nossa manteiga de ervas frescas e cortado em tiras 
                                                    + batatas palito fritas em óleo de flor de algodão + 
                                                    molho Bimbi, nossa receita exclusiva, elaborada com mais de 
                                                    15 ingredientes e um longo tempo de preparo, perfeita para o seu filé.

                                                    </p>
                                                    <span className="price">A partir de R$59,90</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="dish_box">
                                            <div className="dish_info">
                                                <div className="dish_img">
                                                    <img src={ImgDish22} alt="img" />
                                                </div>
                                                <div className="dish_text">
                                                    <h3>Grelhado e Assado + Bambino</h3>
                                                    <p>
                                                    Filé mignon ou peito de frango grelhado com nossa manteiga de ervas e cortados em tiras + arroz cremoso, feito com molho aos queijos e queijo gorgonzola + batatas ao murro assadas no forno com azeite, manteiga de ervas frescas, alho em lascas, tomilho e mais.
                                                    </p>
                                                    <span className="price">A partir de R$48,90</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="dish_box">
                                            <div className="dish_info">
                                                <div className="dish_img">
                                                    <img src={ImgDish23} alt="img" />
                                                </div>
                                                <div className="dish_text">
                                                    <h3>Ragú de Filé Mignon + Bambino</h3>
                                                    <p>
                                                    Massa artesanal feita na manteiga com ervas, servida com ragú de filé mignon cozido lentamente em fogo baixo com ervas frescas, vinho tinto e molho sugo de tomates. O longo cozimento intensifica os sabores e a suculência dessa tradicional receita italiana.
                                                    </p>
                                                    <span className="price">A partir de R$59,90</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <Link to="/menu" className="btn btn_primary">
                                        MENU COMPLETO
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className={`tab-pane fade ${tabMenu.d && "show active"}`} id="deserts" role="tabpanel" aria-labelledby="deserts-tab" >
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                        <div className="dish_box">
                                            <div className="dish_info">
                                                <div className="dish_img">
                                                    <img src={ImgDish15} alt="Brownie ao Leite" />
                                                </div>
                                                <div className="dish_text">
                                                    <h3>Brownie ao Leite</h3>
                                                    <p>
                                                        Massa na proporção certa de chocolate belga 
                                                        ao leite, cobertos com uma casquinha irresistível.
                                                    </p>
                                                    <span className="price">R$9,90</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="dish_box">
                                            <div className="dish_info">
                                                <div className="dish_img">
                                                    <img src={ImgDish16} alt="Brownie com Nozes" />
                                                </div>
                                                <div className="dish_text">
                                                    <h3>Brownie com Nozes</h3>
                                                    <p>
                                                    Massa feita com chocolate belga derretido e pedaços de nozes.{" "}
                                                    </p>
                                                    <span className="price">R$9,90</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="dish_box">
                                            <div className="dish_info">
                                                <div className="dish_img">
                                                    <img src={ImgDish19} alt="img" />
                                                </div>
                                                <div className="dish_text">
                                                    <h3>Brownie Meio Amargo</h3>
                                                    <p>
                                                    Massa feita com chocolate meio amargo e pedaços de chocolate belga derretidos.{" "}
                                                    </p>
                                                    <span className="price">R$9,90</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="dish_box">
                                            <div className="dish_info">
                                                <div className="dish_img">
                                                    <img src={ImgDish17} alt="Brownie Duo Parmeca" />
                                                </div>
                                                <div className="dish_text">
                                                    <h3>Brownie Duo</h3>
                                                    <p>
                                                    Massa dupla: metade de chocolate belga ao leite e metade de chocolate branco. 
                                                    Ela ainda é coberta com sua casquinha crocante e gotas de chocolate.
                                                    </p>
                                                    <span className="price">R$9,90</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="dish_box">
                                            <div className="dish_info">
                                                <div className="dish_img">
                                                    <img src={ImgDish24} alt="Brownie Duo Parmeca" />
                                                </div>
                                                <div className="dish_text">
                                                    <h3>Brownie de Nutella</h3>
                                                    <p>
                                                    Massa feita com chocolate meio amargo belga e um arco de Nutella.
                                                    </p>
                                                    <span className="price">R$10,90</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="dish_box">
                                            <div className="dish_info">
                                                <div className="dish_img">
                                                    <img src={ImgDish18} alt="Minicups Haagen Dazs" />
                                                </div>
                                                <div className="dish_text">
                                                    <h3>Minicups Haagen Dazs</h3>
                                                    <p>
                                                        Além dos melhores parmegianas, trouxemos 
                                                        agora para você o melhor sorvete!
                                                    </p>
                                                    <span className="price">R$21,90</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <Link to="/menu" className="btn btn_primary">
                                        MENU COMPLETO
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MenuSection