import React from "react";
import { Link } from "react-router-dom";
import chefimg01 from '../../assets/images/parmeca-ceo.png'
import Img01 from '../../assets/images/chef_cap.png'

function Chef() {
  return (
    <>
      <section className="chef_section row_am bg_white_textcher">
        <div className="container">
          <div className="row my-2 my-sm-4">
            <div className="col-md-6" data-aos="fade-up" data-aos-duration={1500} >
              <div className="chef_img">
                <img className="radius_img img-fluid" src={chefimg01} alt="img" />
              </div>
              <h3 className="chef_name">
                <span>Ricardo Giulliano Tena & Pedro Coutinho</span>
              </h3>
            </div>
            <div className="col-md-6">
              <div className="abt_chef" data-aos="fade-up" data-aos-duration={1500} >
                <div className="banner_badge">
                  <img className="img-fluid" src={Img01} alt="img" />
                </div>
                <div className="section_title">
                  <span className="sub_text">Parmeca com Muito Amor</span>
                  <h2 className="mb-3">
                  Muitos anos de <br /> Experiência com Parmegiana
                  </h2>
                  <p>
                  O Parmegiana é um prato criado por ítalo-brasileiros: União entre a culinária tradicional Italiana e a nossa cultura Brasileira. O Parmeca nasceu com a missão de proporcionar a melhor experiência gastronômica: Única e memorável.
                  </p>
                </div>
                <Link to="https://deliverydireto.com.br/parmeca" className="btn btn_text">
                  Peça pelo APP <i className="icofont-long-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Chef;
