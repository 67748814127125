import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/images/parmeca-logo.png'
import MenuImg from '../../assets/images/parmeca-parmegiana.png'

function Main() {

  const [active, setActive] = useState();
  const [Nav, setNav] = useState(false);
  const [Home, setHome] = useState();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);


  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleMenuItemClick = () => {
    closeMenu();
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <>
      {active &&
        <div className="menu-backdrop" style={{ opacity: "1", visibility: "visible" }}></div>
      }
      <header className={`fixed ${menuOpen ? 'menu-open' : ''}`}>
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg">
            <Link className="navbar-brand" to="/">
              <img src={logo} alt="image" />
            </Link>
            <button className={`navbar-toggler ${menuOpen ? 'open' : ''}`} type="button" onClick={toggleMenu}>
              <span className="navbar-toggler-icon" onClick={() => setHome(Home === true ? false : true)}>
                <span className={`toggle-wrap ${menuOpen ? "active" : ""}`}>
                  <span className="toggle-bar"></span>
                </span>
              </span>
            </button>
            <div className={`collapse navbar-collapse ${menuOpen ? 'show' : ''}`} id="navbarSupportedContent">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link className="nav-link" to="/" onClick={handleMenuItemClick}>
                   Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/sobrenos" onClick={handleMenuItemClick}>
                   Sobre Nós
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/menu" onClick={handleMenuItemClick}>
                    Menu
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contato" onClick={handleMenuItemClick}>
                    Contato
                  </Link>
                </li>
              </ul>
            </div>

            <div className="action_bar">
              <Link to="https://deliverydireto.com.br/parmeca" className="btn btn_primary" onClick={handleMenuItemClick}>
                Peça pelo Aplicativo
              </Link>
              <div className="bar" onClick={() => setNav(true)}>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </nav>
        </div>
      </header>

      {Nav &&
        <div className={`right_sidemenu ${Nav && "active"}`}>
          <span className="close_rightmenu" onClick={() => setNav(false)}>
            <i className="icofont-close-circled" />
          </span>
          <div className="menu_inner_content">
            <Link to="/contato" className="side_logo">
              <img src={logo} alt="image" />
            </Link>
            <ul className="contact_listing">
              <li>
                <p className="sub_title">Ligue Agora</p>
                <div className="cnt_block">
                  <Link to="tel:+1132073737">Aclimação: (11) 3207-3737</Link>
                  <span>
                    <i className="icofont-ui-call" />
                  </span>
                </div>
                <div className="cnt_block">
                  <Link to="tel:+11910412193">Perdizes: (11) 3873-1000</Link>
                  <span>
                    <i className="icofont-ui-call" />
                  </span>
                </div>
              </li>
              <li>
                <p className="sub_title">Envie um Feedback</p>
                <div className="cnt_block">
                  <Link to="mailto:">contato@parmeca.com.br</Link>
                  <span>
                    <i className="icofont-envelope-open" />
                  </span>
                </div>
              </li>
              <li>
                <p className="sub_title">Endereços</p>
                <div className="cnt_block">
                  <p>Aclimação: R. José Getúlio, 375</p>
                  <span>
                    <i className="icofont-location-pin" />
                  </span>
                </div>
                <div className="cnt_block">
                  <p>Perdizes: R. João Ramalho, 1370</p>
                  <span>
                    <i className="icofont-location-pin" />
                  </span>
                </div>
              </li>
            </ul>
            <Link to="https://deliverydireto.com.br/parmeca" className="btn btn_primary">
              Peça pelo Aplicativo
            </Link>
            <figure className="tab_img">
              <img src={MenuImg} alt="img" />
            </figure>
          </div>
        </div>
      }
    </>
  )
}

export default Main