import React from 'react'
import AboutImg from '../../assets/images/browniecaseiro-png.png'
import Icon01 from '../../assets/images/title_icon.png'
import abt01 from '../../assets/images/gnochi-fotografia.webp'
import abt02 from '../../assets/images/diversos-parmegiana-02.webp'
import abt03 from '../../assets/images/parmegiana-mais-massas.webp'
import abt04 from '../../assets/images/parmegiana-e-pastas.webp'
import abt05 from '../../assets/images/parmegianas-vegetarianas.webp'

function AbtBanner() {
  return (
    <>
      <section className="abt_banner">
        <div className="section_title text-center">
          <span className="icon" data-aos="fade-down" data-aos-duration={1500}>
            <img src={Icon01} alt="img" />
          </span>
          <span className="sub_text mb-1" data-aos="fade-down" data-aos-duration={1500} >
            Um novo conceito de parmegiana:{" "}
          </span>
          <h2 data-aos="zoom-in" data-aos-duration={1500}>
            Personalize cada detalhe do seu prato, desde o queijo e o molho até os acompanhamentos. Culinária com técnicas originais, especializada em delivery.
          </h2>
          <p className="lead" data-aos="fade-up" data-aos-duration={1500}>
            Você escolhe filet mignon, frango ou opção vegetariana.
          </p>
        </div>
        <div className="abt_img">
          <div className="img" data-aos="fade-right" data-aos-duration={1500} data-aos-delay={200} >
            <img src={abt01} alt="img" />
          </div>
          <div className="img" data-aos="fade-right" data-aos-duration={1500} data-aos-delay={100} >
            <img src={abt02} alt="img" />
          </div>
          <div className="img" data-aos="fade-up" data-aos-duration={1500}>
            <img src={abt03} alt="img" />
          </div>
          <div className="img" data-aos="fade-left" data-aos-duration={1500} data-aos-delay={100} >
            <img src={abt04} alt="img" />
          </div>
          <div className="img" data-aos="fade-left" data-aos-duration={1500} data-aos-delay={200} >
            <img src={abt05} alt="img" />
          </div>
        </div>
      </section>
    </>
  )
}

export default AbtBanner